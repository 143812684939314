// Copyright 2018 Red Hat, Inc
// Copyright 2022 Acme Gating, LLC
//
// Licensed under the Apache License, Version 2.0 (the "License"); you may
// not use this file except in compliance with the License. You may obtain
// a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
// WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
// License for the specific language governing permissions and limitations
// under the License.

import {
  PIPELINES_FETCH_FAIL,
  PIPELINES_FETCH_REQUEST,
  PIPELINES_FETCH_SUCCESS
} from '../actions/pipelines'

export default (state = {
  isFetching: false,
  pipelines: {},
}, action) => {
  switch (action.type) {
    case PIPELINES_FETCH_REQUEST:
      return {
        isFetching: true,
        pipelines: state.pipelines,
      }
    case PIPELINES_FETCH_SUCCESS:
      return {
        isFetching: false,
        pipelines: { ...state.pipelines, [action.tenant]: action.pipelines },
      }
    case PIPELINES_FETCH_FAIL:
      return {
        isFetching: false,
        pipelines: state.pipelines,
      }
    default:
      return state
  }
}
