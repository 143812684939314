// Copyright 2021 BMW Group
//
// Licensed under the Apache License, Version 2.0 (the "License"); you may
// not use this file except in compliance with the License. You may obtain
// a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
// WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
// License for the specific language governing permissions and limitations
// under the License.

export const COMPONENTS_FETCH_REQUEST = 'COMPONENTS_FETCH_REQUEST'
export const COMPONENTS_FETCH_SUCCESS = 'COMPONENTS_FETCH_SUCCESS'
export const COMPONENTS_FETCH_FAIL = 'COMPONENTS_FETCH_FAIL'
