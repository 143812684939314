// Copyright 2018 Red Hat, Inc
//
// Licensed under the Apache License, Version 2.0 (the "License"); you may
// not use this file except in compliance with the License. You may obtain
// a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
// WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
// License for the specific language governing permissions and limitations
// under the License.

import React from 'react'
import PropTypes from 'prop-types'


class SourceContext extends React.Component {
  static propTypes = {
    context: PropTypes.object.isRequired,
    showBranch: PropTypes.bool
  }

  render() {
    const { context, showBranch } = this.props
    return (
      <span>
        {context.project}
        {showBranch && context.branch !== 'master' &&
        ' (' + context.branch + ')'}
        : {context.path}
      </span>
    )
  }
}

export default SourceContext
