// Copyright 2021 Red Hat, Inc
//
// Licensed under the Apache License, Version 2.0 (the "License"); you may
// not use this file except in compliance with the License. You may obtain
// a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
// WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
// License for the specific language governing permissions and limitations
// under the License.

import React from 'react'
import PropTypes from 'prop-types'

import {
  Checkbox,
} from '@patternfly/react-core'


function FilterCheckbox(props) {
  const { filters, category, onFilterChange } = props

  function onChange(checked) {
    const newFilters = {
      ...filters,
      [category.key]: checked ? [1,] : [],
    }
    onFilterChange(newFilters)
  }

  return (
    <Checkbox
      label={category.placeholder}
      isChecked={[...filters[category.key]].pop() ? true : false}
      onChange={onChange}
      aria-label={category.placeholder}
    />
  )
}

FilterCheckbox.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
}

export { FilterCheckbox }